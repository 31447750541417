import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.initProgress();
  }

  initProgress() {
    const progressBars = document.querySelectorAll('.progress-bar');
    progressBars.forEach((bar) => {
      if (parseInt(bar.dataset.percentage) >= 10) {
        bar.style.width = `${bar.dataset.percentage}%`;
      }
    })
  }
}
