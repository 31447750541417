import { Controller } from "stimulus";
import { initMapbox } from "../plugins/init_mapbox";

export default class extends Controller {
  static targets = ['tablinks'];

  openTab(event) {

    const activeTab = this.tablinksTarget.querySelector(".active");
    const tabToActivate = event.currentTarget;

    // Get all elements with class="tablinks" and remove the class "active"
    if (activeTab) {
      document.getElementById(activeTab.innerText).classList.add("d-none");
      activeTab.classList.remove("active");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(tabToActivate.innerText).classList.remove("d-none");
    tabToActivate.classList.add("active");

    // To render the map properly inside a Tab
    const mapElement = document.getElementById('map');
    if(mapElement){
      if (tabToActivate.innerText === 'Praças') {
        mapElement.classList.remove('d-none');
        initMapbox();
      } else {
        mapElement.classList.add('d-none');
      }
    }
  }

  selectTab() {
    const activeTab = this.tablinksTarget.querySelector(".active");
    const tabToActivate = event.currentTarget;

    // Remove the class active from the current active link
    if (activeTab) {
      activeTab.classList.remove("active");
    }

    // Add the class active to the link that corresponds to the turbo frame
    tabToActivate.classList.add("active");
  }
}
