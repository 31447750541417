const previewImageOnFileSelect = () => {
  if (document.getElementById('form-image')) {
    // Add the listener to know when the image is clicked
    const imagePreview = document.getElementById('form-image');
    imagePreview.addEventListener('click', () => {
      deletePreview(input);
    })
  }

  // Select the photo input
  const input = document.getElementById('photo-input');
  if (input) {
    // Add a listener to know when a new picture is uploaded
    input.addEventListener('change', () => {
      // Call the displayPreview function (who retrieve the image url and display it)
      displayPreview(input);
    })
  }
}

const displayPreview = (input) => {
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = (event) => {
      document.getElementById('photo-preview').src = event.currentTarget.result;
    }
    reader.readAsDataURL(input.files[0])
    document.getElementById('form-image').classList.remove('d-none');
  }
}

const deletePreview = (input) => {
  // Clear the input info
  input.value = ''

  // Remove the image src and hide the preview
  document.getElementById('photo-preview').src = '';
  document.getElementById('form-image').classList.add('d-none');
}

export { previewImageOnFileSelect };
