import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "filename", "spinIcon"]

  // On conncect sets the loading icon hidden
  connect() {
    this.spinIconTarget.hidden = true;
  }

  // Add filename to the disabled input field - in order to the user see his choice
  update(e) {
    if (e.currentTarget.files.length >= 1) {
      this.filenameTarget.value = e.currentTarget.files[0].name
    }
  }

  // Start loading icon on the screen after clicking the submit button (unhidden)
  runIcon(e) {
    this.spinIconTarget.hidden = false;
  }
}
