import { Controller } from "stimulus";
import { initMapbox } from "../plugins/init_mapbox";

export default class extends Controller {
  static targets = [];

  connect() {
    initMapbox();
  }
}
