// app/javascript/controllers/calendar_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const slotDateLabel = document.getElementsByTagName("abbr")[0];
    if (slotDateLabel) {
      slotDateLabel.innerText = "";
    }
  }

  handleRedirection(event) {
    const slotDiv = event.target.closest('.slot');
    const visitDiv = event.target.closest('.meeting');

    if (slotDiv) {
      this.redirectToSlotShowPage(slotDiv.dataset.slotId);
    } else if (visitDiv) {
      this.redirectToVisitShowPage(visitDiv.dataset.visitId);
    } else {
      this.redirectToNewSlotPage(event.currentTarget.dataset.date);
    }
  }

  redirectToSlotShowPage(slotId) {
    window.location.href = `/slots/${slotId}`;
  }

  redirectToVisitShowPage(visitId) {
    window.location.href = `/visits/${visitId}`;
  }

  redirectToNewSlotPage(selectedDate) {
    const formattedDate = this.formatDate(selectedDate);
    const redirectUrl = `/slots/new?date=${formattedDate}`;
    window.location.href = redirectUrl;
  }

  formatDate(selectedDate) {
    const [month, year] = this.getMonthAndYear();
    const monthMap = {
      jan: 0,
      fev: 1,
      mar: 2,
      abr: 3,
      mai: 4,
      jun: 5,
      jul: 6,
      ago: 7,
      set: 8,
      out: 9,
      nov: 10,
      dez: 11
    };
    const monthNumber = monthMap[month.toLowerCase()];
    const date = new Date(year, monthNumber, selectedDate);
    return date.toISOString().split('T')[0];
  }

  getMonthAndYear() {
    const monthAndYear = document.querySelector('.calendar-title').textContent.split(' ');
    return [monthAndYear[0], monthAndYear[1]];
  }
}
