import { Controller } from "stimulus";

export default class extends Controller {

  copyLink(event) {
    event.preventDefault();
    const cb = navigator.clipboard;
    const link = event.currentTarget.href;
    const projectCreatedAt = event.currentTarget.dataset.projectCreatedAt;

    cb.writeText(link).then(() => {
      if (projectCreatedAt) {
        alert(`Link copiado. A senha para desbloquear o calendário é: ${projectCreatedAt}.`);
      } else {
        alert('Link copiado');
      }
    });
  }
}
