import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["filesCard"]

  connect() {
  }

  selectFile(e) {
    const files = Array.from(e.currentTarget.files);
    const filesCard = this.filesCardTarget;

    // Remove name of previously Selected Files using CSS class
    const filesBeforeSelected = document.querySelectorAll(".selected-file");
    filesBeforeSelected.forEach((selected) => {
      selected.remove(); // selected.innerHTML = ""; selected.classList.remove("selected-file");
    })

    // Show name of new Selected Files when there is more than 1 File
    if (files && files.length > 1) {
      filesCard.style.display = "block";
      this.showFiles(files, filesCard);
    } else {
      filesCard.style.display = "none";
    }
  }

  showFiles(files, filesCard) {
    files.forEach((file) => {
      // Create element 'div' and show File Name
      const selectedFileName = document.createElement("div");
      selectedFileName.classList.add("selected-file");
      selectedFileName.innerHTML = file.name;
      // Create element 'i' and show Paper Icon
      const defaultFileIcon = document.createElement("i");
      defaultFileIcon.classList.add("far", "fa-file-alt");

      // Insert each HTML Element created inside another (like a Child)
      selectedFileName.append(defaultFileIcon);
      filesCard.append(selectedFileName);
    })
  }
}
