import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = ["box", "btn", "counter", "specialtyCheckbox", "professional", "regionCheckbox"]

  connect() {
    $(document).on('click', '.dropdown-menu', function (e) {
      e.stopPropagation();
    });
  }

  filterProfessionals() {
    const chosenSpecialties = this.specialtyCheckboxTargets.filter(box => box.checked).map(box => box.value);
    const chosenRegions = this.regionCheckboxTargets.filter(box => box.checked).map(box => box.value);

    const chosenProfessionals = [];
    const otherProfessionals = [];

    // Filter the professionals by Specialty
    if(chosenSpecialties.length > 0){
      chosenProfessionals.push(this.professionalTargets.filter(p => chosenSpecialties.includes(p.dataset.specialty)));
      otherProfessionals.push(this.professionalTargets.filter(p => !chosenSpecialties.includes(p.dataset.specialty)));
    }
    console.log(chosenProfessionals.flat());

    // Filter the professionals by Region
    if (chosenRegions.length > 0) {
      chosenProfessionals.push(this.professionalTargets.filter(p => chosenRegions.includes(p.dataset.region)));
      otherProfessionals.push(this.professionalTargets.filter(p => !chosenRegions.includes(p.dataset.region)));
    }
    console.log(chosenProfessionals.flat());

    // Shows the chosen professionals and hide the other professionals unless none of the checkboxes was checked
    if(chosenSpecialties.length > 0 || chosenRegions.length > 0){
      chosenProfessionals.flat().forEach(prof => { prof.classList.remove('d-none') });
      otherProfessionals.flat().forEach(prof => { prof.classList.add('d-none') });
    }
    else {
      this.professionalTargets.forEach(prof => { prof.classList.remove('d-none') });
    }
  }

  toggle() {
    this.boxTargets.filter(box => !box.closest('.grid-row').classList.contains('d-none')).forEach((box) => {
      box.checked = event.currentTarget.checked;
    })
    if (this.counterTarget){
      this.counterTarget.innerText = this.boxTargets.filter(box => box.checked).length;
    }
  }

  toggleOne() {
    if (event.currentTarget.checked){
      this.counterTarget.innerText = parseInt(this.counterTarget.innerText) + 1;
    } else {
      this.counterTarget.innerText = parseInt(this.counterTarget.innerText) - 1;
    }
    if (this.boxTargets.every(box => box.checked)) {
      this.btnTarget.checked = true;
    } else {
      this.btnTarget.checked = false;
    }
  }
}
