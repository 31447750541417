import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", 'price']

  connect = () => {
    document.querySelectorAll('input').forEach(($input) => {
      if ($input.dataset.mask !== undefined) {
        if ($input.dataset.action === undefined) {
          $input.setAttribute('data-action', 'input->masks#inputmask');
        } else {
          $input.dataset.action += " input->masks#inputmask"
        }
        $input.value = this.masks($input.dataset.mask, $input.value);
      }
    })
  }

  inputmask = (e) => {
    const input = e.currentTarget;
    const field = input.dataset.mask;
    if (field !== undefined) {
      input.value = this.masks(field, input.value);
    }
  }

  masks = (type, value) => {
    switch (type) {
      case 'phone':
        return value
          .replace(/\D/g, '')
          .replace(/(\d{2})/, '($1) ')
          .replace(/(\d{4})(\d)/, '$1-$2')
          .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
          .replace(/(-\d{4})\d+?$/, '$1')
        break;
      case 'cep':
        return value
          .replace(/\D/g, '')
          .replace(/(\d{5})(\d)/, '$1-$2')
          .replace(/(-\d{3})\d+?$/, '$1')
        break;
      case 'numbers':
        return value
          .replace(/\D/g, '')
        break;
      case 'instagram':
        return value
          .replace(/(^@*)([\w\.]*)/g, '@$2')
        break;
      case 'cnpj':
        return value
          .replace(/\D/g, '')
          .replace(/(\d{2})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1/$2')
          .replace(/(\d{4})(\d)/, '$1-$2')
          .replace(/(-\d{2})\d+?$/, '$1')
        break;
      case 'cpf':
        return value
          .replace(/\D/g, '')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d{1,2})/, '$1-$2')
          .replace(/(-\d{2})\d+?$/, '$1');
        break;
    }
  }
}
